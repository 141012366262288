<template>
  <div class="foot-box">
    <div class="bg-box">
      <div class="center-box">
        <div style="flex: 0.31">
          <div class="title">友情链接</div>
          <ul class="link-contact">
            <li>
              <a href="https://www.nju.edu.cn" target="_black">南京大学</a>
            </li>
            <li>
              <a href="http://aqf.climblue.com" target="_black"
                >南京创蓝科技有限公司</a
              >
            </li>
            <li>
              <a href="https://www.helsinki.fi/en" target="_black"
                >赫尔辛基大学</a
              >
            </li>
            <li>
              <a href="https://jirlatest.nju.edu.cn/" target="_black"
                >大气与地球系统科学实验室</a
              >
            </li>
            <li>
              <a href="https://as.nju.edu.cn" target="_black"
                >南京大学大气科学学院
              </a>
            </li>

            <li>
              <a href="https://www.mee.gov.cn/" target="_black"
                >中华人民共和国生态环境部</a
              >
            </li>
          </ul>
        </div>

        <div style="flex: 0.3">
          <div class="title">联系我们</div>
          <ul class="content-us">
            <li>
              <i class="iconfont icon-dianhua"></i>
              <p class="info">025-58225630</p>
            </li>
            <li>
              <i class="iconfont icon-email"></i>
              <p class="info">info@nagr.com.cn</p>
            </li>
            <li>
              <i class="iconfont icon-dizhi"></i>
              <p class="info">南京市浦口区浦滨路320号科创一号大厦A座22楼</p>
            </li>
          </ul>
        </div>
        <div style="flex: 0.3">
          <div class="title">关注我们</div>
          <div class="QR-code">
            <div class="code"></div>
            <p>扫码关注公众号</p>
          </div>
        </div>
      </div>
    </div>

    <div class="copyright-box">
      南京大气环境与绿色发展研究院 Copyright © 2020 NAGR. All Rights Reserved |
      <a href="https://beian.miit.gov.cn" target="_blank" style="color:white">苏ICP备19060535号-1</a> |
      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32011102010473" target="_blank" style="color:white">苏公网安备32011102010473号</a> 
    </div>
  </div>
</template>

<script>
export default { name: "FOOT" };
</script>

<style lang="scss" scoped>
@import "~assets/css/components/foot.scss";
</style>
