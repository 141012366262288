<template>
  <div class="home-box">
    <Head :head-type="headType" />
    <Nav :nav-type="headType" />
    <router-view />
    <Foot />
  </div>
  <el-backtop />
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
import Head from "components/Head.vue";
import Foot from "components/Foot.vue";
import Nav from "components/Nav.vue";
export default {
  name: "Home",
  components: { Head, Foot, Nav },
  setup() {
    const route = useRoute();
    const headType = computed(() => (route.path == "/" ? "normal" : "white"));
    return { headType };
  },
};
</script>

<style lang="scss" scoped>
.home-box {
  width: 100%;
  background: #f0f5fa;
}
</style>
