<template>
  <div :class="['head-box', headType]">
    <div class="center-box">
      <div class="left-box">
        <a class="logo-link" href="/" target="_black" />
        <div class="title">
          <h1>南京浦蓝大气环境研究院有限公司</h1>
          <h2>
            Nanjing Pulan Atmospheric Environment Research Institute Co. LTD
          </h2>
          <h3>南京浦蓝大气环境与绿色发展研究院</h3>
        </div>
      </div>

      <div class="right-box">
        <!-- <div class="search-box">
          <input class="search-input" type="text" placeholder="请输入关键字" />
          <span class="iconfont icon-sousuo"></span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headType: {
      type: String,
      default: "normal",
    },
  },
  name: "HEAD",
};
</script>

<style scoped lang="scss">
@import "~assets/css/components/head.scss";
</style>
