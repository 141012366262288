<template>
  <div :class="['nav', navType]">
    <div class="main-box">
      <ul class="list-box">
        <router-link to="/" exact> 首页 </router-link>

        <router-link to="/About" custom v-slot="{ isActive, navigate }" exact>
          <li @click="navigate" :class="isActive ? 'router-link-active' : ''">
            关于我们
          </li>
        </router-link>

        <router-link
          to="/Business"
          custom
          v-slot="{ isActive, navigate }"
          exact
        >
          <li @click="navigate" :class="isActive ? 'router-link-active' : ''">
            主营业务
          </li>
        </router-link>

        <router-link to="/Incubate" custom v-slot="{ navigate }" exact>
          <li
            @click="navigate"
            :class="
              route.path == '/Incubate' || route.path == '/CompanyDetail'
                ? 'router-link-active'
                : ''
            "
          >
            孵化引进
          </li>
        </router-link>

        <router-link
          to="/NewsCenter"
          custom
          v-slot="{ isActive, navigate }"
          exact
        >
          <li @click="navigate" :class="isActive ? 'router-link-active' : ''">
            新闻中心
          </li>
        </router-link>

        <router-link to="/Recruit" custom v-slot="{ isActive, navigate }" exact>
          <li @click="navigate" :class="isActive ? 'router-link-active' : ''">
            汇聚英才
          </li>
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
export default {
  props: {
    navType: {
      type: String,
      default: "normal",
    },
  },
  setup() {
    const route = useRoute();
    return { route };
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/css/components/nav.scss";
</style>
